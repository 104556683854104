@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
  font-size: 62.5%;
  font-style: normal;
}

html {
  scroll-behavior: smooth;
}

a {
  text-decoration: none;
}
li {
  list-style: none;
}

.container {
  max-width: 1050px;
  width: 100%;
  margin: 0 auto;
  @media (max-width: 1024px) {
    padding: 0 4rem !important;
  }
  @media (max-width: 768px) {
    padding: 0 2rem !important;
  }
}
.ant-spin {
  width: 100% !important;
}
.main {
  overflow: hidden;
}
